import { FC, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import DoorLockSideNavBar  from './DoorLockSideNavBar';
import { Typography } from '@mui/material';
import FarmSideNavBar from './FarmGuardSidenavbar';

interface drawerProps{
    drawerStatus?:boolean
  }
const SideNavBar : FC<drawerProps> =(props:drawerProps) : ReactElement => {

    const selectedApplication = useSelector((state: RootState) => state.application)
    
    const user = useSelector((state: RootState) => state.user)
    const{drawerStatus}=props
    switch(selectedApplication.name){
        case 'Smart Door Lock Detector and Monitor' : return <DoorLockSideNavBar role={user.role} drawerStatus={drawerStatus}/>
        case 'Farm Guard' : return <FarmSideNavBar role={user.role} drawerStatus={drawerStatus}/>
        default: return(<Typography> No side bar</Typography>)
    }
}

export default SideNavBar