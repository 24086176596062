import React, { useState } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './assets/css/App.css'
import './assets/css/bootstrap.min.css'
import './assets/css/agency.min.css'
import mainLogo from './assets/img/mainLogo.png'
import img1 from './assets/img/images1.jpeg'
import img2 from './assets/img/images2.jpeg'
import img3 from './assets/img/images3.jpeg'
import img4 from './assets/img/images4.jpeg'
import img5 from './assets/img/1.jpg'
import img6 from './assets/img/2.jpg'
import img7 from './assets/img/3.jpg'
import img8 from './assets/img/4.jpg'
import CardComponent from './components/projects';
import vit1 from './assets/img/vit1.png'
import vit2 from './assets/img/vit2.png'
import ssn from './assets/img/ssn.png'
import idsschool from './assets/img/idsschool.png'
import cmc from './assets/img/cmc_logo.png'
import narayani from './assets/img/narayani_logo.png'
import water from './assets/img/water_management.png'
import energy from './assets/img/energy_management.png'
// import doorlock from 'src/screens/Landing/assets/img/door_lock.mp4'
import farmland from './assets/img/farmland.jpg'
import whatsappnumber from './assets/img/whatsappnumber.jpg'

function LandingPage() {

  const data1={name:'Farm Guard',Type:'Automatic',Features:'Monitoring land,Protect crops from animal intrusions',Sensor:'Yes',
  Range:'Anywhere',Brand:'BG Enterprises (BGENT)'}
  const data2={name:'Smart Door Lock Detector and Monitor',Type:'Automatic & Manual',Features:'Energy Saving, Resistant, Monitoring',Sensor:'Yes',
  Range:'Anywhere',Brand:'BG Enterprises (BGENT)'}
  const data3={name:'Smart Timer Controller',Type:'Energy Saving, Manual & Automatic',Features:'Energy efficiency & Control from anywhere & Monitor',Sensor:'Yes',
  Range:'Full based on WiFi',Brand:'BG Enterprises (BGENT)'}
  const data4={name:'Smart Motion Detector',Type:'Automatic',Features:'Energy Saving',Sensor:'Yes',
  Range:'Anywhere',Brand:'BG Enterprises (BGENT)'}
  
  const [selected,setSelected]=useState('home');
  const[isHovering,setIsHovering]=useState({'1':false,'2':false,'3':false,'4':false,'5':false,'6':false,'7':false,'8':false,});
  const styles=(data:any)=>{
    // setTimeout(()=>{},1000)
    if(data===selected){
      return {textDecoration:'underline',color:'#fed136',fontWeight:'bold'}
    }
    else{
        return
    }
    
  }
  const navigate=useNavigate()
//   console.log(selected)
  const [bgcolor,setColor]=useState('transparent')

  // const handleMouseEnter=(obj:any)=>{
  //   setIsHovering({...obj:true})
  // }
  // const handleMouseLeave=()=>{
  //   setIsHovering(false)
  // }className="navbar navbar-dark navbar-expand-lg fixed-top"#backgroundColor:'#2F2B2B',height:'15px'
  return (
    <div className="App">
      <nav  className="navbar navbar-dark navbar-expand-lg fixed-top" id="mainNav" style={{height:'15px'}}>
    <div className="container">
    <a className="navbar-brand js-scroll-trigger" href="#page-top"><img src={mainLogo} alt="img not found" style={logoStyle}/></a>
      <a className="navbar-brand js-scroll-trigger" href="#page-top">BGENT</a>
      
      <button className="navbar-toggler navbar-toggler-right" onClick={()=>bgcolor==='transparent'?setColor('#000'):setColor('transparent')} type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        Menu
        <i className="fa fa-bars"></i>
      </button>
      <div className="collapse navbar-collapse topnav" id="navbarResponsive" style={{backgroundColor:bgcolor}}>
        <ul className="navbar-nav text-uppercase ml-auto">
        <li className="nav-item"  onClick={()=>setSelected('home')}>
            <a className="nav-link js-scroll-trigger" style={styles('home')} href="#">Home</a>
          </li>
          <li className="nav-item" onClick={()=>setSelected('vision&mission')}>
            <a className="nav-link js-scroll-trigger" style={styles('vision&mission')} href="#vision&mission">Vision & Mission</a>
          </li>
          <li className="nav-item" onClick={()=>setSelected('products')}>
            <a className="nav-link js-scroll-trigger" style={styles('products')} href="#products">products</a>
          </li>
          <li className="nav-item" onClick={()=>setSelected('Testimonials')}>
            <a className="nav-link js-scroll-trigger" style={styles('testimonials')} href="#testimonials">Testimonials</a>
          </li>
          <li className="nav-item" onClick={()=>setSelected('Clients')}>
            <a className="nav-link js-scroll-trigger" style={styles('Clients')} href="#Clients">Clients</a>
          </li>
          <li className="nav-item"  onClick={()=>setSelected('about')}>
            <a className="nav-link js-scroll-trigger" style={styles('about')} href="#about">About</a>
          </li>
          <li className="nav-item" onClick={()=>setSelected('team')}>
            <a className="nav-link js-scroll-trigger" style={styles('services')} href="#services">Services</a>
          </li>
          <li className="nav-item" onClick={()=>setSelected('contact')}>
            <a className="nav-link js-scroll-trigger" style={styles('contact')} href="#contact">Contact</a>
          </li>
          <li className="nav-item" >
            {/* <a className="nav-link js-scroll-trigger"  style={styles('login')} onClick={()=>navigate('/login')} >Login</a> 
            sx={{ backgroundColor:'#fed136',color:'#000',float:'right',borderRadius:5,mt:1, ":hover": { backgroundColor: '#fed136',color:'#000' } }}
            */}
            <Button id='login' variant='contained' color='success' style={{marginTop:'6px'}} onClick={()=>navigate('/login')}>Login</Button>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div id="demo" className="carousel slide" data-ride="carousel">


  <ul className="carousel-indicators">
    <li data-target="#demo" data-slide-to="0" className="active"></li>
    <li data-target="#demo" data-slide-to="1"></li>
    <li data-target="#demo" data-slide-to="2"></li>
    <li data-target="#demo" data-slide-to="3"></li>
  </ul>
  

  <div className="carousel-inner" onMouseEnter={()=>setSelected('home')}>
    <div className="carousel-item active">
      <img style={{width: '100%',height: '100vh',filter:'brightness(80%)'}} src={img1} alt="Los Angeles" width="1100" height="500"/>
      <div className="carousel-caption" style={{transform:'translateY(-300%)'}}>
        <p style={{lineHeight:1}}><span style={{color:'#ffce14',fontSize:'3.3em',fontWeight:'bold',letterSpacing:'21px',fontFamily:'arial'}}>BG ENTERPRISES</span><br/>
        <span style={{color:'#fff',fontSize:'1.3em',fontWeight:'bold',letterSpacing:'10px',fontFamily:'arial'}}>WATER LEVEL CONTROLLER & INDICATOR</span></p>
      </div>
    </div>
    <div className="carousel-item">
      <img style={{width: '100%',height: '100vh',filter:'brightness(80%)'}} src={img2} alt="Chicago" width="1100" height="500"/>
      <div className="carousel-caption" style={{transform:'translateY(-300%)'}}>
        <p style={{lineHeight:1}}><span style={{color:'#ffce14',fontSize:'3.3em',fontWeight:'bold',letterSpacing:'21px',fontFamily:'arial'}}>BG ENTERPRISES</span><br/>
        <span style={{color:'#fff',fontSize:'1.3em',fontWeight:'bold',letterSpacing:'10px',fontFamily:'arial'}}>WITH YOUR LOVE WE CAN SAVE THE WORLD</span></p>
      </div>
    </div>
    <div className="carousel-item">
      <img style={{width: '100%',height: '100vh',filter:'brightness(80%)'}} src={img3} alt="New York" width="1100" height="500"/>
      <div className="carousel-caption" style={{transform:'translateY(-300%)'}}>
        <p style={{lineHeight:1}}><span style={{color:'#ffce14',fontSize:'3.3em',fontWeight:'bold',letterSpacing:'21px',fontFamily:'arial'}}>BG ENTERPRISES</span><br/>
        <span style={{color:'#fff',fontSize:'1.3em',fontWeight:'bold',letterSpacing:'10px',fontFamily:'arial'}}>POWER SAVER AND ENERGY SPARING</span></p>
      </div>
    </div>
    <div className="carousel-item">
      <img style={{width: '100%',height: '100vh',filter:'brightness(80%)'}} src={img4} alt="New York" width="1100" height="500"/>
      <div className="carousel-caption" style={{transform:'translateY(-300%)'}}>
        <p style={{lineHeight:1}}><span style={{color:'#ffce14',fontSize:'3.3em',fontWeight:'bold',letterSpacing:'21px',fontFamily:'arial'}}>BG ENTERPRISES</span><br/>
        <span style={{color:'#fff',fontSize:'1.3em',fontWeight:'bold',letterSpacing:'10px',fontFamily:'arial'}}>AI & ML BASED FARM GUARD</span></p>
      </div>
    </div>
  </div>
  

  <a className="carousel-control-prev" href="#demo" data-slide="prev">
    <span className="carousel-control-prev-icon"></span>
  </a>
  <a className="carousel-control-next" href="#demo" data-slide="next">
    <span className="carousel-control-next-icon"></span>
  </a>
</div>


  {/* <div style={{position: 'relative', width: '100%', height: 0, padding-top: '56.2500%',
 padding-bottom: 0, box-shadow: '0 2px 8px 0 rgba(63,69,81,0.16)', margin-top: '1.6em', margin-bottom: '0.9em', overflow: 'hidden',
 border-radius: '8px', will-change: 'transform',}}>
  <iframe loading="lazy" style={{position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, border: 'none', padding: 0,margin: 0}}
    src="https:&#x2F,&#x2F,www.canva.com&#x2F,design&#x2F,DAFQ9J9yBm4&#x2F,watch?embed" allowfullscreen="allowfullscreen" allow="fullscreen">
  </iframe>
</div> */}


  <section className="page-section" id="vision&mission" onMouseEnter={()=>setSelected('vision&mission')}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Vision & Mission</h2>
          <h3 className="section-subheading text-muted">We work till zero wastage of water & electricity is achieved in this earth & to save environment.</h3>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-3 mt-2" onMouseEnter={()=>{setIsHovering({...isHovering,'1':true,'2':false,'3':false,'4':false,'5':false,'6':false,'7':false,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'1':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['1']?'0px':''}} >
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-kmg-design-detailed-outline-kmg-design/64/000000/external-save-water-ecology-kmg-design-detailed-outline-kmg-design.png"/></i>
          </span>
          <h4 className="service-heading"  style={{marginTop:isHovering['1']?'-155px':'',textShadow:isHovering['1']?'1px 1px grey':'',textDecoration:isHovering['1']?'underline':""}}>Save water</h4>
          <p className="text-muted" style={{marginTop:isHovering['1']?'130px':''}}>A drop of water is worth more than a sack of gold to a thirsty man.</p>
        </div>
        <div className="col-md-3 mt-2" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':true,'3':false,'4':false,'5':false,'6':false,'7':false,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'2':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['2']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-wanicon-lineal-wanicon/64/000000/external-light-smart-home-wanicon-lineal-wanicon.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['2']?'-155px':'',textShadow:isHovering['2']?'1px 1px grey':'',textDecoration:isHovering['2']?'underline':""}}>Save Energy</h4>
          <p className="text-muted" style={{marginTop:isHovering['2']?'130px':''}}>A drop of oil burnt, is a bucket of glacier melted</p>
        </div>
        <div className="col-md-3 mt-2" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':false,'3':true,'4':false,'5':false,'6':false,'7':false,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'3':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['3']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/ios/50/000000/money-box--v1.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['3']?'-155px':'',textShadow:isHovering['3']?'1px 1px grey':'',textDecoration:isHovering['3']?'underline':""}}>Save Money</h4>
          <p className="text-muted" style={{marginTop:isHovering['3']?'130px':''}}>Get the best product and best experience for the best price</p>
        </div>
        <div className="col-md-3 mt-2" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':false,'3':false,'4':true,'5':false,'6':false,'7':false,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'4':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['4']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-others-cattaleeya-thongsriphong/64/000000/external-Water-ecology-outline-others-cattaleeya-thongsriphong.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['4']?'-155px':'',textShadow:isHovering['4']?'1px 1px grey':'',textDecoration:isHovering['4']?'underline':""}}>Save water</h4>
          <p className="text-muted" style={{marginTop:isHovering['4']?'130px':''}}>Automatic & Manual type Energy Saving, Resistant, Monitoring .</p>
        </div>
        <div className="col-md-3 mt-5" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':false,'3':false,'4':false,'5':true,'6':false,'7':false,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'5':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['5']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-itim2101-lineal-itim2101/64/000000/external-smart-home-internet-of-things-itim2101-lineal-itim2101.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['5']?'-155px':'',textShadow:isHovering['5']?'1px 1px grey':'',textDecoration:isHovering['5']?'underline':""}} >IOT Projects</h4>
          <p className="text-muted" style={{marginTop:isHovering['5']?'130px':''}}>The internet of things has the potential to change the world just as the internet did may be even more.</p>
        </div>
        <div className="col-md-3 mt-5" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':false,'3':false,'4':false,'5':false,'6':true,'7':false,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'6':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['6']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-itim2101-lineal-itim2101/64/000000/external-laptop-devices-service-itim2101-lineal-itim2101-2.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['6']?'-155px':'',textShadow:isHovering['6']?'1px 1px grey':'',textDecoration:isHovering['6']?'underline':""}}>Moniter & Control</h4>
          <p className="text-muted" style={{marginTop:isHovering['6']?'130px':''}}>The best way to save things is to monitor and control and that is what we are doing.</p>
        </div>
        <div className="col-md-3 mt-5" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':false,'3':false,'4':false,'5':false,'6':false,'7':true,'8':false,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'7':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['7']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-out-line-pongsakorn-tan/64/000000/external-installation-help-and-support-out-line-pongsakorn-tan.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['7']?'-155px':'',textShadow:isHovering['7']?'1px 1px grey':'',textDecoration:isHovering['7']?'underline':""}}>Easy Installation</h4>
          <p className="text-muted" style={{marginTop:isHovering['7']?'130px':''}}>With existing setup, we can install our product without disturbing any circuits.</p>
        </div>
        <div className="col-md-3 mt-5" onMouseEnter={()=>{setIsHovering({...isHovering,'1':false,'2':false,'3':false,'4':false,'5':false,'6':false,'7':false,'8':true,})}} onMouseLeave={()=>{setIsHovering({...isHovering,'8':false})}}>
          <span className="fa-stack fa-4x" style={{marginTop:isHovering['8']?'0px':''}}>
            <i className="fa fa-circle fa-stack-2x text-primary"></i>
            <i className="fa-stack-1x"><img style={{marginBottom:'15px'}}src="https://img.icons8.com/external-flaticons-lineal-flat-icons/64/000000/external-services-inhome-service-flaticons-lineal-flat-icons-2.png"/></i>
          </span>
          <h4 className="service-heading" style={{marginTop:isHovering['8']?'-155px':'',textShadow:isHovering['8']?'1px 1px grey':'',textDecoration:isHovering['8']?'underline':""}}>Best Service</h4>
          <p className="text-muted" style={{marginTop:isHovering['8']?'130px':''}}>We assure you of our best service at all times.</p>
        </div>
      </div>
    </div>
  </section>

  <section className="page-section" id="products" style={{backgroundColor:'#F5F5F5'}} onMouseEnter={()=>setSelected('products')}>
      <div className="row mx-auto">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Our Products</h2>
          <div className="row text-center ">
          <div className="col-md-3">
          <CardComponent data={data1} />
          </div>
          <div className="col-md-3">
          <CardComponent data={data2}/>
          </div>
          <div className="col-md-3">
          <CardComponent data={data3} />
          </div>
          <div className="col-md-3">
          <CardComponent data={data4}/>
          </div>
          </div>
        </div>
      </div>     
  </section>
  <section className="page-section" id="testimonials" style={{backgroundColor:'#F5F5F5'}} onMouseEnter={()=>setSelected('products')}>
      <div className="row mx-auto">
        <div className="col-lg-12 text-center">
        <div className="heading-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <div className="col-lg-6 text-center" style={{ flex: '1', textAlign: 'center' }}>
        <h2 className="section-heading text-uppercase">Testimonials</h2>
      </div>
      <div className="col-lg-6 text-center" style={{ flex: '1', textAlign: 'center' }}>
        <h2 className="section-heading text-uppercase">Real Time Captures</h2>
      </div>
      </div>
          <div className="row text-center ">
          <div className="col-md-3">
          <img style={{width: '100%',height: '50vh',filter:'brightness(100%)'}} src={img6} alt="New York" width="1100" height="500"/>
          </div>
          <div className="col-md-3">
          <img style={{width: '100%',height: '50vh',filter:'brightness(100%)'}} src={img7} alt="New York" width="1100" height="500"/>
          </div>
          <div className="col-md-3">
          <img style={{width: '100%',height: '50vh',filter:'brightness(100%)'}} src={img5} alt="New York" width="1100" height="500"/>
          </div>
          <div className="col-md-3">
          <img style={{width: '100%',height: '50vh',filter:'brightness(100%)'}} src={img8} alt="New York" width="1100" height="500"/>
          </div>
          </div>
        </div>
      </div>     
  </section>

  {/*<section className="page-section" id="projects" onMouseEnter={()=>setSelected('projects')}>
      <video autoPlay loop width='80%' src={doorlock} controls controlsList="nodownload"/>
</section>*/}


  <section className="page-section" id="Clients" onMouseEnter={()=>setSelected('Clients')}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Our Valuable Customers</h2>
          <div className="row text-center ">
          <div className="col-md-2">
         <img src={vit1} alt='' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src={vit2} alt='' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src={ssn} alt='' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src={idsschool} alt='' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src={cmc} alt='' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src={narayani} alt='' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src="https://pbs.twimg.com/profile_images/1547506146713686016/3tnGE369_400x400.jpg" alt='Crescent' style={{width:'150px',height:'150px'}}></img>
          </div>
          <div className="col-md-2">
         <img src="https://dtnext-prod.s3.ap-south-1.amazonaws.com/imported/import/Articles/2020/Aug/202008070504145030_Finally-new-forest-officers-appointed-for-reserves-circles_SECVPF.gif" alt='Crescent' style={{width:'200px',height:'150px'}}></img>
          </div>
          </div>
        </div>
        
      </div>
      </div>
      </section>
  
  <section className="page-section" id="about" style={{backgroundColor:'#F5F5F5'}} onMouseEnter={()=>setSelected('about')}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">About Us</h2>
          <h3 className="section-subheading text-muted"></h3>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <ul className="timeline">
            <li>
              <div className="timeline-image" >
                <img  style={{marginTop:'15px',width:'125px',height:'125px'}} src="https://img.icons8.com/clouds/100/000000/potted-plant.png" alt=""/>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>Dec-2018 </h4>
                  <h4 className="subheading">Our Journey Begins</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">
                  BGENT is a pioneering company specializing in the research, development, and manufacturing of innovative electrical and electronic devices. With a strong focus on societal welfare, our products aim to conserve vital resources such as water and electricity. With over three years of experience in the R&D sector, we are committed to delivering cutting-edge solutions that make a meaningful impact.
                  </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img style={{marginTop:'25px',width:'100px',height:'100px'}} src="https://img.icons8.com/external-wanicon-lineal-color-wanicon/64/000000/external-plant-free-time-wanicon-lineal-color-wanicon.png" alt=""/>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>Jan-2019</h4>
                  <h4 className="subheading">Our First Breakthrough</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">In January 2019, we achieved a significant milestone with the creation of our first product, the Water Level Controller. This device revolutionizes water management by automatically preventing overflow, thereby minimizing water wastage.
                  </p>
                </div>
              </div>
            </li>
            <li>
              <div className="timeline-image">
                <img style={{marginTop:'25px',width:'100px',height:'100px'}} className="" src="https://img.icons8.com/external-nawicon-outline-color-nawicon/64/000000/external-door-lock-internet-of-things-nawicon-outline-color-nawicon.png" alt=""/>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>2019</h4>
                  <h4 className="subheading">A Year of Innovation</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">In 2019, we introduced the Smart Door Lock Detector and Monitor, a groundbreaking device that utilizes IoT and sensors. 
                  Designed for college hostels and hotels, this device intelligently manages electrical instruments, ensuring they are turned off when the door is closed from outside and 
                  turned on when the door is opened. Our innovative approach earned us recognition from prestigious institutions such as VIT Vellore, VIT Chennai, and SSN Chennai.                 
                   </p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img style={{marginTop:'25px',width:'100px',height:'100px'}} className="rounded-circle img-fluid" src="https://img.icons8.com/external-flaticons-flat-flat-icons/64/000000/external-artificial-intelligence-robotics-flaticons-flat-flat-icons.png" alt=""/>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>2021</h4>
                  <h4 className="subheading">Harnessing Advanced Technologies(AI & ML)</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">In 2021, we continued our innovation journey by leveraging AI (Artificial Intelligence) and ML (Machine Learning) technologies. Our IoT-based Farm Guard device is specifically designed for farmers, providing advanced protection for farmland against animal intrusion.</p>
                </div>
              </div>
            </li>
            <li className="timeline-panel">
              <div className="timeline-image">
                <img style={{marginTop:'25px',width:'125px',}} className="rounded-circle img-fluid" src="https://img.icons8.com/bubbles/100/bullish.png" alt=""/>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>2022</h4>
                  <h4 className="subheading">Scaling New Heights</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">In 2022, BGENT witnessed significant growth in the installation of our Smart Door Lock Detector and Monitor. 
                  Due to its proven effectiveness and reliability, we expanded our reach and installed door locks in a larger number in college hostels. 
                  This expansion not only highlights the trust placed in our innovative solutions but also underscores our commitment to enhancing security and convenience for our clients.</p>
                </div>
                <div className="timeline-heading">
                  <h4 className="subheading">Continued Innovation</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">Furthermore, our R&D efforts continued to drive innovation in the agricultural sector. 
                  Development on the Farm Guard model progressed steadily, with a focus on enhancing its capabilities and effectiveness. 
                  The Farm Guard remains a key focus area for us as we strive to provide farmers with cutting-edge technology to protect their farmlands from animal intrusion. 
                  Our dedication to leveraging advanced technologies such as AI and ML ensures that the Farm Guard will set a new standard in farm security and efficiency.</p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <img style={{marginTop:'25px',width:'125px',height:'125px'}} className="rounded-circle img-fluid" src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/000000/external-motion-sensor-augmented-reality-flaticons-lineal-color-flat-icons.png" alt=""/>
              </div>
              <div className="timeline-panel">
                <div className="timeline-heading">
                  <h4>2023</h4>
                  <h4 className="subheading">Innovative Energy Conservation</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">In 2023, BGENT introduced a groundbreaking device aimed at conserving electricity in educational institutions and hostel facilities. 
                  The new Smart Motion Sensor is designed to intelligently manage lighting and electrical appliances in classrooms, corridors, and hostel bathrooms. 
                  By detecting motion and occupancy, the sensor ensures that lights and appliances are only activated when needed, thus reducing unnecessary energy consumption and contributing to sustainability efforts.</p>
                </div>
                <div className="timeline-heading">
                  <h4 className="subheading">Expanding Reach and Impact</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">Our commitment to excellence led to an expansion of our client base, with Crescent Chennai College joining the ranks of institutions benefiting from our cutting-edge solutions. 
                  This expansion is a testament to the trust and confidence placed in our products.</p>
                </div>
                <div className="timeline-heading">
                  <h4 className="subheading">Revolutionizing Farm Land Protection</h4>
                </div>
                <div className="timeline-body">
                  <p className="text-muted">In a significant development, our Farm Guard model completed its development phase and underwent rigorous testing. The results were outstanding, proving that animals are automatically diverted from farmlands, effectively protecting crops. 
                  Our innovative approach to farm security has garnered attention from government officials, leading to meetings with state and central ministers. 
                  These discussions are focused on deploying the Farm Guard in the forest RFA area to prevent animals entering into people living areas.</p>
                </div>
              </div>
            </li>
            <li className="timeline-inverted">
              <div className="timeline-image">
                <h4>Be Part
                  <br/>Of Our
                  <br/>Story!</h4>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>


  <section className="theme-counter bg-light page-section" id="services" onMouseEnter={()=>setSelected('services')}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Services</h2>
          <h3 className="section-subheading text-muted"></h3>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4">
          <div className="team-member">
            <img className="mx-auto rounded-circle" src={water} alt="" />
            <h4>Water Management</h4>
            <p className="text-muted"><i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Smart Water Flow Monitor & Controller<br/>
            <i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Fully Automatic water Level Controller with Dry-run protection</p>
           
          </div>
        </div>
        <div className="col-sm-4">
          <div className="team-member">
            <img className="mx-auto rounded-circle" style={{color:"#fed136"}} src={energy} alt=""/>
            <h4>Energy Mangement</h4>
            <p className="text-muted"><i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Smart Door Lock Detector and Monitor<br/>
            <i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Smart Motion Detector and Monitoring<br/>
            <i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Smart Time Controller</p>
            
          </div>
        </div>
        <div className="col-sm-4">
          <div className="team-member">
            <img className="mx-auto rounded-circle" src={farmland} alt=""/>
            <h4>Agriculture Management</h4>
            <p className="text-muted"><i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Farm Guard<br/>
            <i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Protect Farm land from animals intrusion<br/>
            <i className="fa fa-check-circle" aria-hidden="true" style={{color:"#fed136"}}></i><span> {''}</span>Get the Food Chain System back</p>
            
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-8 mx-auto text-center">
          <p className="large text-muted"></p>
        </div>
      </div>
    </div>
  </section>
  
  <section className="page-section" id="contact"  onMouseEnter={()=>setSelected('contact')}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h2 className="section-heading text-uppercase">Contact Us</h2>
          <h3 className="section-subheading text-muted">Let &apos;s get in touch</h3>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <form id="contactForm" name="sentMessage">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <input className="form-control" id="name" type="text" placeholder="Your Name *" required data-validation-required-message="Please enter your name."/>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <input className="form-control" id="email" type="email" placeholder="Your Email *" required data-validation-required-message="Please enter your email address."/>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <input className="form-control" id="phone" type="tel" placeholder="Your Phone *" required data-validation-required-message="Please enter your phone number."/>
                  <p className="help-block text-danger"></p>
                </div>
                <div className="form-group">
                  <textarea className="form-control" id="message" placeholder="Your Message *" required data-validation-required-message="Please enter a message."></textarea>
                  <p className="help-block text-danger"></p>
                </div>
              <div className="clearfix"></div>
              <div className="col-lg-12 text-center">
                <div id="success"></div>
                <button id="sendMessageButton" className="btn btn-primary btn-xl text-uppercase" type="submit">Send Message</button>
              </div>
              </div>
              <div className='col-md-6'>
          <div className="form-group">
          <h4 className="section-heading ">Reach Us:</h4>
          <p className="section-heading">Mr.P.G.Ragavandir(CEO),<br/>
          E-mail : ceo@bgenterprises.org.in,<br/>#369, Phase III, Sathuvachari, Vellore, Tamil Nadu 632009.</p><br/>
          <p style={{ alignItems: 'center', display: 'flex' }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.4522748762756!2d79.16461057484085!3d12.942885687369841!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad387057eef1d3%3A0xe54accd01f7775ee!2sBGENT!5e0!3m2!1sen!2sin!4v1713430715901!5m2!1sen!2sin" width="700" height="400" style={{border:0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                 </p>
          </div>
        </div>
            </div>
            
          </form>
        </div>
      </div>
        
    </div>
  </section>

  
  <footer className="footer">
    <div className="container">
      <div className='row align-items-center'>
        <p>
          
        </p>

      </div>
      <div className="row align-items-center">
        
        <div className="col-md-4">
          <ul className="list-inline social-buttons">
            <li className="list-inline-item">
              <a href="#something">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#something">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#something">
                <i className="fa fa-youtube"></i>
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#something">
              <img src={whatsappnumber} style={{width:'75px',height:'75px'}} title='whattsApp'></img>
              </a>
            </li>
            
          </ul>
        </div>
        <div className="col-md-4">
          <span className="copyright">Copyright &copy;2025 BGENT  All Right Reserved</span>
        </div>
      </div>
    </div>
  </footer>
    </div>
  );
}
const logoStyle={
  width:"50px",
  hight:"auto",
  borderRadius:'100px'

}
export default LandingPage;
