import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, Grid, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { FC, ReactElement, useState } from "react";
import { useSelector } from "react-redux";
import { createDeviceService } from 'src/services/deviceService';
import { useNavigate } from "react-router-dom";
import { RootState } from "src/store";
import { IBuilding, IFloor, IRoom } from "src/types";

import AddInstruments from './AddInstruments';
type AlertType = "error" | "success" | "info" | "warning" | undefined;
interface CreateDeviceProps {
    visibility: boolean,
    setVisibility: (a: boolean) => void;
    refreshHome: () => void;
    statusData:(a: boolean)=>void;
    messagesData:(a:AlertType)=>void
}

const CreateDevice: FC<CreateDeviceProps> = (props: CreateDeviceProps): ReactElement => {

    const [formData, setFormData] = useState({
        name: '',
        buildingId: '',
        floorId: '',
        roomId: ''
    })

    const { visibility, setVisibility, refreshHome,statusData,messagesData } = props;
    const navigate=useNavigate();

    const { name, buildingId, floorId, roomId } = formData;

    const[instrumentModel,setInstrumentModel]=useState(false)

    const applicationId = useSelector((state: RootState) => state.application.id)
    const organizationId = useSelector((state: RootState) => state.organization.id)
    const buildings = useSelector((state: RootState) => state.buildings.buildings)
    const floors = useSelector((state: RootState) => state.floors.floors)
    const rooms = useSelector((state: RootState) => state.rooms.rooms)

    const handleClick = async (e: any) => {
        e.preventDefault();
        const totalWatts=addedInstruments.filter((x:any)=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.watts},0)
        const totalQuantity=addedInstruments.filter((x:any)=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.quantity},0)
        // console.log(name, applicationId, organizationId, buildingId,floorId, roomId,totalWatts,addedInstruments,totalQuantity,'dataforcreatedevice')
        const response = await createDeviceService({ name:name, applicationId:applicationId, organizationId:organizationId, buildingId:buildingId,floorId:floorId, roomId:roomId,totalWattsOfInstruments:totalWatts,instrumentList:addedInstruments,quantity:totalQuantity })
        if (response.status === 200) {
            statusData(true)
            messagesData('success')
            refreshHome()
            setVisibility(false)
            setTimeout(()=>{
                navigate('../home')
                navigate('../devices/')
            },100)
        }
        
        
    }

    const handleClose = () => {
        setAddedInstruments([{_id:'', instrumentName: '', quantity: 0,watts:0, isSelected: false,isItemSelected:false }])
        setVisibility(false)       
    }

    const handleChange = (e: any) => {
        e.preventDefault();
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [addedInstruments,setAddedInstruments] =useState([{_id:'', instrumentName: '', quantity: 0,watts:0, isSelected: false,isItemSelected:false }])
    const instrumentsList=(data:any)=>{
        setAddedInstruments([])
        setAddedInstruments(data)
    }
    // console.log(addedInstruments,'addedInstruments_New')                    
    // <Grid > //spacing={3}

    return (
        <>
        <Dialog open={visibility} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" sx={{color:'#660066'}}> Create Device </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Grid >
                        <TextField color="secondary" sx={{ mb: 1,color:'#660066' }} onChange={handleChange} value={name} fullWidth id="name" name="name" label="Name" variant="standard" />

                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-lablel"> Building </InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="buildingId" onChange={handleChange} >
                                <MenuItem value=""><em> None </em></MenuItem>
                                {
                                    buildings.map((x: IBuilding) => {
                                        return <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-lablel"> Floor </InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="floorId" onChange={handleChange} value={floorId}>
                                <MenuItem value=""><em> None </em></MenuItem>
                                {
                                    floors.filter((x: IFloor) => x.buildingId === buildingId).map((x: IFloor) => {
                                        return <MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>
                                    })
                                }
                            </Select>
                        </FormControl>

                        <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
                            <InputLabel id="demo-simple-select-lablel"> Room </InputLabel>
                            <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="roomId" onChange={handleChange} value={roomId}>
                                <MenuItem value=""><em> None </em></MenuItem>
                                {
                                    rooms.filter((x: IRoom) => (x.buildingId === buildingId && x.floorId === floorId )).map((x: IFloor) => {

                                        return <MenuItem value={x.id} key={x.id}>{x.number}</MenuItem>

                                    })
                                }
                            </Select>
                        </FormControl>
                        {/* <FormControl color="secondary" fullWidth variant="standard" >
                         <div style={{ fontWeight:'bold',display:'flex',justifyContent:'space-between' }}>
                         <Typography sx={{marginTop:'5px',fontWeight:'bold'}}>Instrument Name</Typography>
                         <Typography sx={{fontWeight:'bold'}}>Quantity</Typography>  
                            <Typography sx={{fontWeight:'bold'}}>Watts</Typography>                                                     
                            </div>                                                       
                        </FormControl> */}
                        
                        <FormControl color="secondary" fullWidth variant="outlined" >
                        <Typography sx={{fontWeight:'bold',fontSize:25,color:'#660066'}}>Instruents List :-</Typography>
                        <table style={{border:'1px solid grey'}}>
                        
                        <tr style={{border:'1px solid grey'}}>
                                <th style={{textAlign:'left',color:'#660066'}}>Sl.No</th>
                                <th style={{textAlign:'left',color:'#660066'}}>Instrument Name</th>
                                <th style={{textAlign:'left',color:'#660066'}}><Typography sx={{fontWeight:'bold'}}>Quantity</Typography>  </th>
                                <th style={{textAlign:'left',color:'#660066'}}><Typography sx={{fontWeight:'bold',alignItems:'left'}}>Watts</Typography></th>
                            </tr>
                        {addedInstruments.filter(x=>x.instrumentName!=='' && x.isItemSelected===true).map((data:any,index:any)=>{
                            return  <tr key={index}>
                            <td style={{textAlign:'left',color:'#000'}}><Typography sx={{marginTop:'5px'}}>{index+1}</Typography></td>
                            <td style={{textAlign:'left',color:'#000'}}><Typography sx={{marginTop:'5px'}}>{data.instrumentName}</Typography></td>
                            <td style={{textAlign:'center',color:'#000'}}><Typography sx={{}}>{data.quantity}</Typography></td>
                            <td style={{textAlign:'center',color:'#000'}} ><Typography sx={{}}>{data.watts}</Typography></td>                                                  
                            </tr>
                            
                        })}
                        <tr style={{border:'1px solid grey'}}>
                            <td style={{textAlign:'left',color:'#000'}}><Typography sx={{marginTop:'5px'}}>{}</Typography></td>
                            <td style={{textAlign:'left',color:'#660066'}}><Typography sx={{marginTop:'5px',fontWeight:'bold',color:'#660066'}}>Total</Typography></td>
                            <td style={{textAlign:'center',color:'#660066'}}><Typography style={{marginTop:'5px',fontWeight:'bold',color:'#660066'}}>{addedInstruments.filter(x=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.quantity},0)}</Typography></td>
                            <td style={{textAlign:'center',color:'#660066'}} ><Typography style={{marginTop:'5px',fontWeight:'bold',color:'#660066'}}>{addedInstruments.filter(x=>x.instrumentName!=='').reduce((prev,cur)=>{return prev + +cur.watts},0)}</Typography></td>                                                  
                            </tr>
                                                   
                        </table>
                            
                        </FormControl>
                    </Grid>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                
                <Button sx={{backgroundColor:'#660066',color:'#fff',float:'left',":hover":{backgroundColor:'#660066'}}} onClick={()=>setInstrumentModel(true)}> {addedInstruments.filter(x=>x.instrumentName!=='').length===0 ? '+Add Instruments' : 'Edit Instruments' } </Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClose}> Cancel </Button>
                <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={handleClick} autoFocus> Create </Button>
            </DialogActions>
            <AddInstruments visibilityOfInstruments={instrumentModel} setVisibilityOfInstruments={setInstrumentModel} selectedInst={instrumentsList}></AddInstruments>
            
        </Dialog>





        </>
    )

}

export default CreateDevice





// const AddInstruments:FC<IAddInstruments>=(props:IAddInstruments):ReactElement=>{

//     const {visibilityOfInstruments,setVisibilityOfInstruments}=props
//     const applicationId = useSelector((state: RootState) => state.application.id)
//     const organizationId = useSelector((state: RootState) => state.organization.id)
//     const buildings = useSelector((state: RootState) => state.buildings.buildings)
//     const floors = useSelector((state: RootState) => state.floors.floors)
//     const rooms = useSelector((state: RootState) => state.rooms.rooms)
//     return(
//         <Dialog open={visibilityOfInstruments} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" sx={{float:'right',position:'absolute',marginLeft:'150px'}}>
//         <DialogTitle id="alert-dialog-title" sx={{color:'#660066'}}> Create Device </DialogTitle>
//         <DialogContent>
//             <DialogContentText id="alert-dialog-description">
//                 <Grid spacing={3}>
//                     <TextField color="secondary" sx={{ mb: 1,color:'#660066' }}  fullWidth id="name" name="name" label="Name" variant="standard" />

//                     <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
//                         <InputLabel id="demo-simple-select-lablel"> Building </InputLabel>
//                         <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="buildingId">
//                             <MenuItem value=""><em> None </em></MenuItem>
//                             {
//                                 buildings.map((x: IBuilding) => {
//                                     return <MenuItem value={x.id}>{x.name}</MenuItem>
//                                 })
//                             }
//                         </Select>
//                     </FormControl>

//                     <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
//                         <InputLabel id="demo-simple-select-lablel"> Floor </InputLabel>
//                         <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="floorId">
//                             <MenuItem value=""><em> None </em></MenuItem>
//                             {/* {
//                                 floors.filter((x: IFloor) => x.buildingId === buildingId).map((x: IFloor) => {
//                                     return <MenuItem value={x.id}>{x.name}</MenuItem>
//                                 })
//                             } */}
//                         </Select>
//                     </FormControl>

//                     <FormControl color="secondary" fullWidth variant="standard" sx={{ minWidth: 120 }}>
//                         <InputLabel id="demo-simple-select-lablel"> Room </InputLabel>
//                         <Select labelId="demo-simple-select-label" sx={{ mb: 1 }} name="roomId">
//                             <MenuItem value=""><em> None </em></MenuItem>
//                             {/* {
//                                 rooms.filter((x: IRoom) => (x.buildingId === buildingId && x.floorId === floorId )).map((x: IFloor) => {

//                                     return <MenuItem value={x.id}>{x.number}</MenuItem>

//                                 })
//                             } */}
//                         </Select>
//                     </FormControl>
//                 </Grid>
//             </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//             <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={()=>setVisibilityOfInstruments(false)}> Cancel </Button>
//             <Button sx={{backgroundColor:'#660066',color:'#fff',":hover":{backgroundColor:'#660066'}}} onClick={()=>setVisibilityOfInstruments(false)} autoFocus> Submit </Button>
//         </DialogActions>
        
//     </Dialog>
//     )
// }