import { Grid, Typography, Button, Box, Card, CardContent, CardMedia, IconButton, useTheme, CardActionArea, CardActions, Paper, Divider, Chip, Stack } from '@mui/material';
import { FC, Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import PetsIcon from '@mui/icons-material/Pets';
import AnimalIntrusionMonthWiseChart from '../../../../components/homePage/FarmGuard/ChartWidget/AnimalIntrusionMonthWiseChart';
import AnimalIntrusionDayWiseChart from '../../../../components/homePage/FarmGuard/ChartWidget/AnimalIntrusionDayWiseChart'
import VideocamIcon from '@mui/icons-material/Videocam';
import Clock from '../../../../components/homePage/FarmGuard/clockWidget';
import WeatherWidget from '../../../../components/homePage/FarmGuard/weatherWidget';
import Notification from '../../../../components/homePage/FarmGuard/notification';
import Activities from '../../../../components/homePage/FarmGuard/activitiesRecent';
import DashboardFooter from '../../../../components/homePage/FarmGuard/dashboardFooter';
import ImagesRecent from '../../../../components/homePage/FarmGuard/imagesRecent';
import HistoryIcon from '@mui/icons-material/History';
import LandscapeIcon from '@mui/icons-material/Landscape';
import Draggable from 'react-draggable'
import RefreshFarmGuardData from '../../refreshFarmGuardData';
import { formatData } from "src/utils/commonMethods";
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Refresh';
import loader from '../../../../assets/svg/loader.gif'
import { getAlertsByOrganizationId, getFarmAlertsCountByOrg, } from "src/services/alertsFarmService";
import { SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION, SET_FARMALERTSBYORGANISATION } from "src/store/actions/actionTypes";



const OrgUserfarmGuardDashboard: FC = (): ReactElement => {

  const organization = useSelector((state: RootState) => state.organization)
  const application = useSelector((state: RootState) => state.application)
  const farmGuardAlerts = useSelector((state: RootState) => state.farmGuardAlerts.farmAlerts)
  const farmGuardAlertsChart = useSelector((state: RootState) => state.farmGuardAlerts.chart_By_org)
  const LastAlert = useCallback(() => {
    if (farmGuardAlerts.length !== 0) {
      const value = farmGuardAlerts[0].alertStatus.detectedThing
      return value ? value : 'NA'
    }

  }, [farmGuardAlerts])
  const lengthAlerts = farmGuardAlertsChart.dayResponse ? farmGuardAlertsChart.dayResponse.length : 0
  const date = new Date().getDate()
  const month = new Date().getMonth() + 1
  const year = new Date().getFullYear()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [visibility, setVisibility] = useState(false)
  const refreshDataGrid = async () => {
    setVisibility(true)
    const response1 = await getAlertsByOrganizationId({ organizationId: organization.id, applicationId: application.id })
    
    if (response1.status === 200) {
      dispatch({ type: SET_FARMALERTSBYORGANISATION, payload: { value: response1.data.map((x: any) => formatData(x)), fetchStatus: true } })
      // refreshFarmGuardData(dispatch,)
      console.log(response1,"response1")
    }
    const response2 = await getFarmAlertsCountByOrg({ organizationId: organization.id, applicationId: application.id })
    if (response2.status === 200) {
      // refresh_chart_By_org(dispatch,response.data)
      // console.log(response2,"response2")
      dispatch({ type: SET_ALERTS_FARMGUARD_COUNT_BY_ORGANIZATION, payload: { dayResponse: response2.data.dayResponse, monthResponse: response2.data.monthResponse } })
    }
    // navigate('../home')
    // setTimeout(() => { navigate('/home/dashboard') }, 50)
    setTimeout(() => { setVisibility(false) }, 1000)
  }


  return (
    <Fragment>
      <Draggable>
        <Fab sx={{ position: 'fixed', zIndex: 1000, marginTop: '25%' }} aria-label='Edit' color='secondary' onClick={refreshDataGrid}>
          {visibility ? <img src={loader} width='25px' height='25px'></img> : <EditIcon />}
        </Fab>
        </Draggable>
      <div>
        <Grid justifyContent='center' alignItems='flex-start' direction={'column'}>
          <Grid container spacing={1} direction={'row'}>
            <Grid item xs={3} style={{ borderRadius: '25px' }}>
              <Clock />
            </Grid>
            <Grid item xs={5} style={{ borderRadius: '25px' }}>
              <WeatherWidget />
            </Grid>
            <Grid item xs={4}>
              <Grid container spacing={0.1} direction='row'>
                <Grid item xs={6} style={{ borderRadius: '25px' }}>
                  <Notification about='Total Area' icon={<LandscapeIcon />} value='1' message='Acres' />
                  <Notification about='Last alert' icon={<PetsIcon />} value={LastAlert()?LastAlert():'NA'} message='Detected' position='10px' />

                </Grid>
                <Grid item xs={6} style={{ borderRadius: '25px', position: 'relative' }}>
                  <Notification about='Total' icon={<VideocamIcon />} value={organization.cameras.length} message='Cameras' />
                  {lengthAlerts!=0 ?<Notification about='Today' icon={<PriorityHighIcon />} value={farmGuardAlertsChart.dayResponse[lengthAlerts - 1]._id.date === date && farmGuardAlertsChart.dayResponse[length - 1]._id.month === month && farmGuardAlertsChart.dayResponse[lengthAlerts - 1]._id.year === year ? farmGuardAlertsChart.dayResponse[lengthAlerts - 1].total_count : 0} message='Alerts' position='10px' /> :
                  <Notification about='Today' icon={<PriorityHighIcon />} value='0' message='Alerts' position='10px' />
                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={1}
            sx={{
              borderRadius: '5px', backgroundSize: 'cover'
              // backgroundImage:'url(https://c4.wallpaperflare.com/wallpaper/663/67/745/violet-blurry-background-wallpaper-preview.jpg)'
            }} >
            <Stack direction={'row'} spacing={2}>
              <Grid item xs={6} sx={{ paddingRight: '4px', borderRadius: '11px', border: '2px solid #660066' }}>
                <div>
                  <Typography color="#660066" align='center' variant='h5'>
                    Animal Intrusion Day Wise
                  </Typography>
                  <AnimalIntrusionDayWiseChart data={farmGuardAlertsChart.dayResponse} height={300} width={600} />
                </div>
              </Grid>
              <Grid item xs={6} sx={{ paddingLeft: '4px', borderRadius: '11px', border: '2px solid #660066' }}>
                <div>
                  <Typography color="#660066" align='center' variant='h5'>
                    Animal Intrusion Month Wise
                  </Typography>
                  <AnimalIntrusionMonthWiseChart data={farmGuardAlertsChart.monthResponse} height={300} width={600} />
                </div>
              </Grid>
            </Stack>
          </Grid>
          <Grid container spacing={1} direction={'row'} mt={1}
            sx={{ borderRadius: '5px' }} >
            <Grid item xs={6}>
              <Paper elevation={4} style={{ borderRadius: '25px' }}>
                <Card sx={{ width: '100%', height: 'auto', borderRadius: '20px', backgroundColor: '#660066', opacity: 0.8 }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align='center' color='#fff' >
                      <HistoryIcon /> Recent Alerts
                    </Typography>
                    <Activities alerts={farmGuardAlerts} />
                  </CardContent>
                  <CardActionArea>
                    <Typography align="center" variant='body2' color='#fff'> view all alerts </Typography>
                  </CardActionArea>
                </Card>
              </Paper>
            </Grid>
            <Grid item xs={6}>
              <Paper elevation={4} style={{ borderRadius: '25px' }}>
                <Card sx={{ width: '100%', height: 'auto', borderRadius: '20px', backgroundColor: '#660066', opacity: 0.8 }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div" align='center' color='#fff' >
                      <HistoryIcon /> Recent Images
                    </Typography>
                    <ImagesRecent alerts={farmGuardAlerts} />
                  </CardContent>
                  <CardActionArea>
                    <Typography align="center" variant='body2' color='#fff'> view all Images </Typography>
                  </CardActionArea>
                </Card>
              </Paper>
            </Grid>
          </Grid>
          <Grid container spacing={1} direction={'row'} mt={1}
            sx={{ borderRadius: '5px' }} >
            <Grid item xs={12}>
              <DashboardFooter />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <RefreshFarmGuardData />
    </Fragment>
  )
}
export default OrgUserfarmGuardDashboard