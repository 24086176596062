import React, { FC,ReactElement } from "react";
import { Card,CardMedia,Grid } from "@mui/material";
import { WorldMap,ActiveUsers,GlobalActiveUsers,TotalDevices } from "./components";
import {AnnualSalesChart} from './charts'
const SuperAdminFarmGuardDashboard:FC = ():ReactElement=>{
    
    
    return(
        <div>
        <Grid container spacing={6} sx={{justifyContent:'center',alignItems:'center',textAlign:'center'}} >
          <Grid item > 
        <Grid container spacing={6} sx={{justifyContent:'center',alignItems:'center'}} >
          <Grid item><WorldMap/></Grid>
        </Grid>
        </Grid>
        <Grid item > 
        <Grid container spacing={6} sx={{justifyContent:'center',alignItems:'center'}} >
          <Grid item><ActiveUsers/></Grid>
          <Grid item><GlobalActiveUsers/></Grid>
          <Grid item><TotalDevices/></Grid>
        </Grid>
        </Grid>
        </Grid>
        <Grid container spacing={6} marginTop={0.5} sx={{justifyContent:'center',alignItems:'center'}} >
          <Grid item><AnnualSalesChart /></Grid>
          <Grid item><AnnualSalesChart /></Grid>
        </Grid>
        </div>


    )
   
}

export default SuperAdminFarmGuardDashboard
