import React, {FC,ReactElement } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers,faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { color } from 'd3';
const GlobalActiveUsers:FC=():ReactElement=>{
  return (
    <Card sx={{width:'12rem',height:'12rem',backgroundColor:'transparent',borderRadius:'50%',border:'#660066 2px solid',
    textAlign:'center','@media (max-width: 750px)': {
                    width: '12rem',
                    height:'12rem',
                    justifyContent:'center',
                    alignItems:'center', // Set to full width on mobile
                    borderRadius: '50%', // Remove border radius on mobile if needed
                    textAlign:'center'
                },
    }}>
      
        <CardContent>
        <FontAwesomeIcon icon={faEarthAmericas} style={{color:'#660066',width:'2rem',height:'2rem',minWidth:'1.5rem'}}/>
          <Typography gutterBottom variant="h6" component="div" sx={{color:'#660066',fontWeight:'bold'}}>
            Global Users
          </Typography>
          <Typography gutterBottom variant="h3" component="div" sx={{color:'#660066',fontWeight:'bold',fontFamily:'monospace',marginTop:'-1rem'}}>
           1000
          </Typography>
        </CardContent>

      <CardActions sx={{textAlign:'center',justifyContent:'center',alignItems:'center',marginTop:'-2rem'}}>
        <Button size="small" sx={{backgroundColor:"#660066",color:"#fff",justifyItems:'center',":hover":{backgroundColor:'#CC99CC',color:'#fff'}}}>
          USERS LIST
        </Button>
      </CardActions>
    </Card>
  );

}
export default GlobalActiveUsers