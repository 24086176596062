import { Apps, AssignmentInd,Category, CorporateFare, LocationCity, Devices, ExitToApp, Settings, Analytics, Stairs, Room,Summarize } from '@mui/icons-material';
import { Box, Toolbar, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider,Button } from '@mui/material';
import { FC, Fragment, ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { ROLE } from 'src/constants/shared';

import SuperAdminSideNavBar from './SuperAdminSideNavBarDoorLock';
const drawerWidth = 240;

interface items {
    title: string,
    path: string,
    icon: ReactElement,
    component?: ReactElement,
    showMenu: boolean
}

export interface menuProps {
    items: items[]
}

export interface sideBarProps {
    role: string
}
export interface drawerProps{
    drawerStatus?:boolean
}

type Props = sideBarProps & drawerProps
const DoorLockSideNavBar: FC<Props> = (props: Props): ReactElement => {
    const { role,drawerStatus } = props;
    const navigate=useNavigate();

    function refreshPage() {
        navigate('/login/..');
        navigate('/login/..');
        window.location.reload();
    }
    const [selectedItem, setSelectedItem] = useState("/");

    const useStyles = makeStyles({
        paper: {
            background: "#660066",
          }
    })
    const classes = useStyles();
    switch(role){
     case ROLE.Admin : return (

        <Drawer classes={{ paper: classes.paper }} variant="permanent" sx={{width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }, 
        }}>
            <Toolbar />
            <Box>
                <Fragment>
                    <SideBar role={role} setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                    <Divider />
                    <Button onClick={refreshPage} ><MenuItem  icon={<ExitToApp />} title="Log out" > </MenuItem></Button>    
                </Fragment>
            </Box>
        </Drawer >
    );
    case ROLE.User : return (

        <Drawer classes={{ paper: classes.paper }} variant="permanent" sx={{width: drawerWidth, flexShrink: 0, [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' }, 
        }}>
            <Toolbar />
            <Box>
                <Fragment>
                    <SideBar role={role} setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                    <Divider />
                    <Button onClick={refreshPage} ><MenuItem  icon={<ExitToApp />} title="Log out" > </MenuItem></Button> 
                </Fragment>
            </Box>
        </Drawer >
    );

    case ROLE.SuperAdmin : return <SuperAdminSideNavBar drawerStatus={drawerStatus}/>
    default : return <> No Side Navigation Available !!</>

}
}

const useStyles = makeStyles({
    selectedItem: {
        width: 'auto',
        margin: '5px',
        backgroundColor:'#660066',
        // background: 'linear-gradient(45deg, #614385, #516395)',
        color: 'white',
        
        border:'1px solid #fff',
        borderRadius:'8px',
        "&:hover":{boxShadow: "rgb(152 145 145) 1px 5px 15px"}
    },
    defaultItem: {
        width: 'auto',
        margin: '5px',
        backgroundColor: '#660066',
        color: '#CC99CC'
    }
})

interface MenuItemProps {
    title: string,
    path?: string,
    selectedItem?: string,
    setSelectedItem?: any,
    icon: ReactElement
}

const MenuItem: FC<MenuItemProps> = (props: MenuItemProps): ReactElement => {

    const navigate = useNavigate()
    const classes = useStyles();

    const { path, icon, title,setSelectedItem } = props;

    const onClick = (e: any, path: any) => {
        setSelectedItem(path)
        navigate(`../home/${path}`, { replace: true })
    }
    //&&(path && setSelectedItem==path)

    const routePath=window.location.pathname.split('/').filter(e=>e)
    // console.log(routePath,'routePath')
    // console.log(routePath.length===1,'routePathLength')
    return (
        <ListItem className={(routePath.includes(path?path:'/')) ? classes.selectedItem : classes.defaultItem} id={`sidenav_${path}`} button key={`item_path`} onClick={(e) => onClick(e, path)}>
            <ListItemIcon style={(routePath.includes(path?path:'/')) ? { color: 'white' } : { color: '#CC99CC' }}>
                {icon}
            </ListItemIcon>
            <ListItemText primary={title} />
        </ListItem>
    )
}

const SideBar: FC<any> = (props: any): ReactElement => {
    const { role, selectedItem, setSelectedItem } = props;

    switch (role) {
        // case ROLE.SuperAdmin: return <SuperAdminSideBar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        case ROLE.SuperAdmin : return <SuperAdminSideNavBar/>
        case ROLE.Admin: return <OrgAdminSideBar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        case ROLE.User: return <OrgUserSideBar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
        default: return <OrgUserSideBar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
    }
}


const SuperAdminSideBar: FC<any> = (props: any): ReactElement => {

    const { selectedItem, setSelectedItem } = props;

    return (
        <Fragment>
            <List>
                <MenuItem path="dashboard" icon={<Analytics />} title="Dashboard" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="devices" icon={<Devices />} title="Devices" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            <Divider />
            <List>
                <MenuItem path="rooms" icon={<Room />} title="Rooms" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="floors" icon={<Stairs />} title="Floors" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="buildings" icon={<LocationCity />} title="Buildings" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            {/* <Divider />
            <List>
                <MenuItem path="organization" icon={<CorporateFare />} title="Organization" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="applications" icon={<Apps />} title="Applications" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List> */}
            <Divider />
            <List>
                <MenuItem path="Instruments" icon={<Category />} title="Instruments" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="profile" icon={<AssignmentInd />} title="Profile" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="settings" icon={<Settings />} title="Settings" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            <Divider />
            <MenuItem path="studentEntry" icon={<Settings />} title="Student Data" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            
            {/* <MenuItem path={"../"} icon={<ExitToApp />} title="Log Out" onClick={handleClick} /> */}
        </Fragment>
    )
}

const OrgUserSideBar: FC<any> = (props: any): ReactElement => {

    const { selectedItem, setSelectedItem } = props;
    return (
        <Fragment>
            <List>
                <MenuItem path="dashboard" icon={<Analytics />} title="Dashboard" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="devices" icon={<Devices />} title="Devices" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            <Divider />
            <List>
                <MenuItem path="rooms" icon={<Room />} title="Rooms" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="floors" icon={<Stairs />} title="Floors" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="buildings" icon={<LocationCity />} title="Buildings" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            {/* <Divider />
            <List>
                <MenuItem path="organization" icon={<CorporateFare />} title="Organization" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="applications" icon={<Apps />} title="Applications" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List> */}
            <Divider />
            <List>
                <MenuItem path="Instruments" icon={<Category />} title="Instruments" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="profile" icon={<AssignmentInd />} title="Profile" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="settings" icon={<Settings />} title="Settings" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            <Divider />
            <MenuItem path="studentEntry" icon={<Summarize />} title="Student Data" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />           
            {/* <Divider />
            <MenuItem path={"../"} icon={<ExitToApp />} title="Log Out" onClick={handleClick} /> */}
        </Fragment>
    )
}

const OrgAdminSideBar: FC<any> = (props: any): ReactElement => {

    const { selectedItem, setSelectedItem } = props;

    return (
        <Fragment>
            <List>
                <MenuItem path="dashboard" icon={<Analytics />} title="Dashboard" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="devices" icon={<Devices />} title="Devices" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            <Divider />
            <List>
                <MenuItem path="rooms" icon={<Room />} title="Rooms" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="floors" icon={<Stairs />} title="Floors" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="buildings" icon={<LocationCity />} title="Buildings" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            {/* <Divider />
            <List>
                <MenuItem path="organization" icon={<CorporateFare />} title="Organization" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="applications" icon={<Apps />} title="Applications" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List> */}
            <Divider />
            <List>
                <MenuItem path="Instruments" icon={<Category />} title="Instruments" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="profile" icon={<AssignmentInd />} title="Profile" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
                <MenuItem path="settings" icon={<Settings />} title="Settings" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            </List>
            <Divider />
            <MenuItem path="studentEntry" icon={<Settings />} title="Student Data" setSelectedItem={setSelectedItem} selectedItem={selectedItem} />
            {/* <MenuItem path={"../"} icon={<ExitToApp />} title="Log Out" onClick={handleClick} /> */}
        </Fragment>
    )
}

export default DoorLockSideNavBar;