import * as React from 'react';
import { getAccessedApplications, getApplications } from '../services/applicationService'
import { FC, ReactElement, useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import { Grid} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { SET_ACCESSEDAPPLICATIONS, SET_ALLAPPLICATIONS, SET_ORGANIZATIONDATA, SET_ROLEDATA, SET_APPLICATIONDATA } from 'src/store/actions/actionTypes';
import "./SwitchApps.css"
import { APP_NAME, ROLE } from 'src/constants/shared';
import { getUserById } from 'src/services/userService';
// import TopNavBar from 'src/components/homePage/TopNavBar/TopNavBar';
import {TopNavBarMain} from 'src/components/homePage';
import { MainContainer } from 'src/components/homePage';
import CardComponent from 'src/components/homePage/cardcomponent';
const SwitchApps: FC = (props: any): ReactElement => {
    const allApplications = useSelector((state: RootState) => state.application.allApplications)
    const accessedApplications = useSelector((state: RootState) => state.application.accessedApplications)
    const userId = useSelector((state: RootState) => state.user.id)
    const role = useSelector((state: RootState) => state.role)
    const user =useSelector((state:RootState)=>state.user)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [roleName,setRoleName]=useState('')
    useEffect(()=>{
        getRoleName()
    },[])
    useEffect(() => {
        if (allApplications.length === 0) {
            fetchApplication()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allApplications])
    const fetchApplication = async () => {
        if (allApplications.length === 0) {
            const response = await getApplications()
            if (response.data.length !== 0) {
                dispatch({ type: SET_ALLAPPLICATIONS, payload: response.data })
            }
        }
        if (accessedApplications.length === 0) {
            const responseApplications = await getAccessedApplications({ userId: userId })
            if (responseApplications.data.applications.length !== 0) {
                dispatch({ type: SET_ACCESSEDAPPLICATIONS, payload: responseApplications.data.applications })
            }
        }
        if (userId && !role.id) {
            const responseUser = await getUserById({ id: userId })
            if (responseUser.status === 200 && responseUser.data.status === "success") {
                console.log('SET_ORGANIZATIONDATA',responseUser.data.role.name)
                dispatch({ type: SET_ROLEDATA, payload: { name: responseUser.data.role.name, id: responseUser.data.role._id } })
                // if (responseUser.data.role.name !== ROLE.SuperAdmin) {
                dispatch({ type: SET_ORGANIZATIONDATA, payload: { name: responseUser.data.organization.name, id: responseUser.data.organization._id, description : responseUser.data.organization.description, orgCoverImg : responseUser.data.organization.orgCoverImg,cameras:responseUser.data.organization.cameras } })
                return <Navigate to="/dashboard" />
                // } else {
                //     dispatch({ type: SET_ORGANIZATIONDATA, payload: { name: responseUser.data.organization.name, id: responseUser.data.organization._id, description : responseUser.data.organization.description, orgCoverImg : responseUser.data.organization.orgCoverImg,cameras:responseUser.data.organization.cameras } })
                //     return <Navigate to="/dashboard" />
                // }
            }
        }
        return null
    }


    const getRoleName=async()=>{
        const responseUser = await getUserById({ id: userId })
        // console.log('responseUser',responseUser)
        if (responseUser.status === 200 && responseUser.data.status === "success") {
            dispatch({ type: SET_ROLEDATA, payload: { name: responseUser.data.role.name, id: responseUser.data.role._id } })           
            setRoleName(responseUser.data.role.name)

        }
    }

    const navigateHome = (e: any, applicationId: string, applicationName: string) => {
        // if (roleName !== ROLE.SuperAdmin) {
            dispatch({ type: SET_APPLICATIONDATA, payload: { id: applicationId, name: applicationName } })
            navigate(`/home/dashboard`)
        // }
        // else if(roleName === ROLE.SuperAdmin){
        //     dispatch({ type: SET_APPLICATIONDATA, payload: { id: applicationId, name: applicationName } })
        //     navigate(`/home/dashboard`)
        // }

    }
    const checkAccessedApplication = (id: string) => {
        return accessedApplications.findIndex((x: string) => x === id) === -1
    }
    const [newsearch, setnewSearch] = useState('');
    function getValue(name:any) {
        setnewSearch(name.toLocaleLowerCase());
    }
    return (
        // <Box sx={{ display: 'flex' }}>
        <>
        <TopNavBarMain role={user.role} appName={APP_NAME} getData={getValue} drawer={()=>false}/>
              <MainContainer >
              {allApplications.length !== 0 ? (
                <Grid container spacing={10}>
                    {allApplications
                    .filter((x: any) =>x.name.toLocaleLowerCase().includes(newsearch) || newsearch === "")
                        .map((row: any, index: number) => {
                            return (
                <Grid key={index} item>
                <CardComponent data={row} access={checkAccessedApplication} pageNavigate={navigateHome} indexvalue={index}/>
                </Grid>
                )})}
          </Grid>):<h1>No Apps Found</h1>}</MainContainer>
          </>
        //   </Box>
    )
}
export default SwitchApps