import React, {FC,ReactElement } from 'react';
import {Card,CardMedia} from '@mui/material';
import World from '../components/World.png';
const WorldMap:FC=():ReactElement=>{
    return(
    <Card sx={{width:'100%',borderRadius:'15px', boxShadow:'none',backgroundColor:'transparent',
                '@media (max-width: 750px)': {
                    width: '100%',
                    justifyContent:'center',
                    alignItems:'center', // Set to full width on mobile
                    borderRadius: 0, // Remove border radius on mobile if needed
                },
            }}>
            <CardMedia
            component="img"
            height="100%"
            width="50%"
            // Adjust width for regular and mobile view
            sx={{ width: '100%', '@media (max-width: 750px)': { height: '200%' } }}
            // image="https://wallpaperaccess.com/full/1858898.jpg"
            // image="https://simplemaps.com/static/demos/resources/svg-library/svgs/world.svg"
            image={World}//"https://www.clker.com/cliparts/N/C/8/p/C/8/pink-world-map-hi.png"
            alt="World Images"
        />
            </Card>
    )

}
export default WorldMap