import * as React from 'react';
import {FC,ReactElement} from 'react';
import { Typography } from '@mui/material';
import OrgUserDoorLockDashboard from './OrguserDoorLockDashboard';
import {ROLE} from 'src/constants/shared';
import SuperAdminDoorLockDashboard from './SuperAdminDoorLockDashboard'

export interface roleProps{
  role:string
}
const DoorLockDashboard:FC<roleProps>=(props:roleProps):ReactElement=>{
      const {role} =props;
      // console.log(role,'role')
    switch(role){     
      case ROLE.Admin: return <OrgUserDoorLockDashboard/>
      case ROLE.User: return <OrgUserDoorLockDashboard/>
      case ROLE.SuperAdmin:return <SuperAdminDoorLockDashboard/>
      default : return <Typography> DashBoard Not Available !</Typography>
    }
}
export default DoorLockDashboard