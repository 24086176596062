import * as React from 'react';
import { AppBar, Avatar, Button, Toolbar, Typography } from "@mui/material";
import { FC, ReactElement, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RootState } from "src/store";
import { SET_APPLICATIONDATA } from "src/store/actions/actionTypes";
import { stringAvatar } from "../../../constants/shared"
import SearchField from '../SearchField';
import { CompareArrows } from '@mui/icons-material'
import IconButton from '@mui/material/IconButton';
import { useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';;

export const myContext = React.createContext('');
 interface roleProps{
    role:string
}
interface TopNavBarProps {
    appName: string,
}
interface SearchFieldProps {
    getData: (x: string) => void;
}
interface drawerProps{
    drawer:(x:boolean)=>void;
}
type Props = roleProps & TopNavBarProps & SearchFieldProps &drawerProps;
const SuperAdminTopNavBar: FC<Props> = (props: Props): ReactElement => {
    // const theme = useTheme();
    let location=useLocation()
    const [open, setOpen] = useState(false);
    
    const[show,setShow]=useState(false)
    const handleDrawer= () => {
      setOpen(!open);
    };
  
    useEffect(()=>{
        drawer(open)
    },[open])

    const { role,appName, getData,drawer } = props;
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    function refreshPage() {
        navigate('/login/..');
        window.history.back();
        window.location.reload();
      }

    const user = useSelector((state: RootState) => state.user);
    const selectedApplication = useSelector((state: RootState) => state.application)
    const onClickSwitchApps = (e: any) => {
        e.preventDefault();
        dispatch({ type: SET_APPLICATIONDATA, payload: { id: '', name: '' } })
        navigate('/switchApps')
    }


    const [search, setSearch] = useState('');
    function getValue(name: any) {
        setSearch(name);

    }
    useEffect(()=>{
        //locatiom.pathname returns /switchApps
        if (location.pathname !== '/switchApps'){
            setShow(true)
        }

    },[])
    useEffect(() => {
        getData(search.toLocaleLowerCase())
    }, [search])
        return (
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'linear-gradient(45deg, #660066, #660066)' }}>
            
            <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {show?<IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawer}
            edge="start"
            // sx={{
            //   marginRight: 5,
            //   ...(open && { display: 'none' }),
            // }}
            sx={{marginWidth:'15px'}}
          >
            <MenuIcon />
          </IconButton> : <></>}
                <Typography variant="h6" noWrap component="div" color='white'>
                    {appName}
                    {
                        selectedApplication.name !== "" ? (
                            " - " + selectedApplication.name
                        ) : (
                            <></>
                        )
                    }
                </Typography>

                {
                    (user.name && user.name !== "") ? (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {selectedApplication.name === "" ? (
                                <myContext.Provider value={"search"}>
                                    <SearchField getDetail={getValue} />
                                </myContext.Provider>) : (<></>)
                            }

                            {
                                selectedApplication.name !== "" ? (

                                    <Button onClick={onClickSwitchApps} variant="text" sx={{
                                        mr: 3, color: 'white',
                                        // boxShadow: "rgb(152 145 145) 1px 5px 15px",
                                        // border:'1px solid #fff',
                                        borderRadius: '8px', ":hover": { border: '1px solid #fff', boxShadow: "rgb(152 145 145) 1px 5px 15px" }
                                    }}><CompareArrows sx={{ width: '45px' }} />  Switch Apps</Button>
                                ) : (
                                    <></>
                                )
                            }
                            <Avatar {...stringAvatar(user.name)} style={{ marginRight: '10px' }} />
                            <p style={{ whiteSpace: 'nowrap', color: 'white' }}>{user.name}</p>
                            <span style={{width:'5px', color: 'white'}}>{' '}</span>
                            <span style={{width:'5px', color: 'white'}}>{' | '}</span>
                            <Button onClick={refreshPage} style={{ whiteSpace: 'nowrap', color: 'white' }}> Log Out</Button>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </Toolbar>
        </AppBar>
    )
}
export default SuperAdminTopNavBar;
