import * as React from 'react';
import OrgUserfarmGuardDashboard from './OrguserFarmGuardDashboard';
import SuperAdminFarmGuardDashboard from './SuperAdminFarmGuardDashboard';
import {FC,ReactElement} from 'react'
import { ROLE } from 'src/constants/shared';
import { Typography } from '@mui/material';

export interface roleProps{
    role:string
  }
const FarmGuardDashBoard:FC<roleProps>=(props:roleProps):ReactElement=>{
    const {role}=props
    switch(role){     
        case ROLE.Admin: return <OrgUserfarmGuardDashboard/>
        case ROLE.User: return <OrgUserfarmGuardDashboard/>
        case ROLE.SuperAdmin:return <SuperAdminFarmGuardDashboard/>
        default : return <Typography> DashBoard Not Available !</Typography>
      }
}
export default FarmGuardDashBoard;